/**
 * Service Rules Mixin
 */

import i18n from "@/core/plugins/vue-i18n";

export const rulesMixin = {
    data() {
        return  {
            numberRule: [
                (v) => {
                    // if (!v.trim()) return true;
                    if (!isNaN(parseInt(v))) return true;
                    return i18n.t("PAGES.USERS.FORM.MANAGE.ERROR_PHONE");
                },
            ],
            // isNumber: [
            //     (v) => {
            //         var charCode = v.which ? v.which : v.keyCode;
            //         if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
            //             v.preventDefault();
            //             return false;
            //         } else {
            //             return true;
            //         }
            //     },
            // ],
            emailRules: [
                (v) => !!v || i18n.t("PAGES.USERS.ATTRIBUTES.EMAIL_REQUIRED"),
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    i18n.t("PAGES.USERS.ATTRIBUTES.EMAIL_ERROR"),
            ],
            denominationRule: [
                (v) => !!v || i18n.t("PAGES.USERS.ATTRIBUTES.DENOMINATION_REQUIRED"),
                (v) =>
                    (v && v.length > 4) ||
                    i18n.t("PAGES.USERS.ATTRIBUTES.MIN_LENGHT_DENOMINATION"),
            ],
            usernameRules: [
                (v) => !!v || i18n.t("PAGES.USERS.ATTRIBUTES.USERNAME_REQUIRED"),
                (v) =>
                    (v && v.length > 4) ||
                    i18n.t("PAGES.USERS.ATTRIBUTES.MIN_LENGHT_USERNAME"),
            ],
            requiredSelectRules: [
                (v) =>
                    (v && v.length > 0) ||
                    i18n.t("COMMON.ERRORS.REQUIRED"),
            ],
            requiredRules: [(v) => !!v || i18n.t("COMMON.ERRORS.REQUIRED")],
            fiscalCodeRule: [(v) => !!v ||  i18n.t("PAGES.CITIZENS.FORM.MANAGE.ERROR_FISCAL_CODE_USED")],
            requiredPasswordRules: [(v) => {
                if (!this.passwordChangeFlag) {
                    return true;
                }
                return !!v || i18n.t("COMMON.ERRORS.REQUIRED")
            }]
        }
    },
    methods: {
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
                return false;
            } else {
                return true;
            }
        },
    }
};